import React from 'react';
import get from 'lodash.get';

import { ErrorBoundary } from 'components/ErrorBoundary';
import { AdController, FrontAdController } from 'lib/AdControllers';
import { TaboolaController } from 'lib/TaboolaControllers';
import { getFeatureConfigForBrand } from 'lib/getFeatureStatus';
import { TABOOLA_CUSTOM_PLACEMENT } from 'lib/brandFeatures';
import LayoutContext from './LayoutContext';
import FullWidth from './FullWidth';
import LeftRail from './LeftRail';
import LeftRailWithSplitRow from './LeftRailWithSplitRow';
import RightRail from './RightRail';
import RightRailTabbed from './RightRailTabbed';
import RightRailWithSplitRow from './RightRailWithSplitRow';

/**
 * Layout type values.
 * @enum {string}
 */
export const LAYOUT_TYPES = {
  FULL_WIDTH: 'fullWidth',
  LEFT_RAIL: 'leftRail',
  LEFT_RAIL_WITH_SPLIT_ROW: 'leftRailWithSplitRow',
  RIGHT_RAIL: 'rightRail',
  RIGHT_RAIL_TABBED: 'rightRailTabbed',
  RIGHT_RAIL_WITH_SPLIT_ROW: 'rightRailWithSplitRow',
  /** @deprecated - Use rightRailTabbed instead. */
  RIGHT_RAIL_TABBED_TODAY: 'rightRailTabbedToday',
};

/**
 * Returns the layout component for the given layout type
 * @param {'fullWidth' | 'leftRail' | 'leftRailWithSplitRow' | 'rightRail' | 'rightRailTabbed' | 'rightRailWithSplitRow' } layoutType - Type of layout
 * @param {*} props - props to pass to the layout component
 * @param {boolean} forceOverrideRightRailTab - https://nbcnewsdigital.atlassian.net/browse/NGDHC-8477
 * @returns Layout component
 */
export function getLayoutFor(layoutType, props, forceOverrideRightRailTab = 'false') {
  switch (layoutType) {
    case LAYOUT_TYPES.FULL_WIDTH:
      return <FullWidth {...props} />;
    case LAYOUT_TYPES.LEFT_RAIL:
      return <LeftRail {...props} />;
    case LAYOUT_TYPES.LEFT_RAIL_WITH_SPLIT_ROW:
      return <LeftRailWithSplitRow {...props} />;
    case LAYOUT_TYPES.RIGHT_RAIL:
      return <RightRail {...props} />;
    case LAYOUT_TYPES.RIGHT_RAIL_TABBED:
      return forceOverrideRightRailTab ? <RightRail {...props} /> : <RightRailTabbed {...props} />;
    case LAYOUT_TYPES.RIGHT_RAIL_WITH_SPLIT_ROW:
      return <RightRailWithSplitRow {...props} />;
    case LAYOUT_TYPES.RIGHT_RAIL_TABBED_TODAY:
      console.warn('LayouType "rightRailTabbedToday" is deprecated. Using "rightRailTabbed" instead.');
      return <RightRailTabbed {...props} />;
    default:
      return null;
  }
}

const getLayout = ({
  adsDisabled,
  curationAutofill,
  layouts,
  pageRoute,
  vertical,
  pageType,
  isFluidWidthPage,
  routePath,
}) => {
  const isCover = pageRoute === '/';
  const isNewsVertical = vertical === 'news';
  const isGlobalCitizen = vertical === 'globalcitizen';

  const isTaboolaCustomPlacement = getFeatureConfigForBrand(
    TABOOLA_CUSTOM_PLACEMENT,
    vertical,
  );

  let adController;
  let customFrontAds;
  if (isFluidWidthPage) {
    adController = new AdController(vertical, layouts, 'today');
    customFrontAds = new FrontAdController(vertical, layouts, pageType);
  } else if (isCover && !isGlobalCitizen) {
    const adRulesKey = vertical;
    adController = new AdController(vertical, layouts, adRulesKey);
  } else {
    adController = new FrontAdController(vertical, layouts, pageType);
  }

  let taboolaController;
  if (isCover && isTaboolaCustomPlacement) {
    taboolaController = new TaboolaController(vertical);
  }

  const isEligibleForAdInsertion = ['news', 'today', 'msnbc', 'globalcitizen'].includes(vertical);
  let foundFirstRail = false;
  let leadPackageType = null;
  let foundFirstLayoutWithPackages = false;
  const nonPackageAdsDisabled = get(adController, 'nonPackageAdsDisabled', false);

  return (layout, index) => {
    const { type, id } = layout;
    let { packages } = layout;
    packages.slice(0);
    let isFirstLayoutWithPackages = false;
    if (layout && packages && !foundFirstLayoutWithPackages) {
      foundFirstLayoutWithPackages = true;
      isFirstLayoutWithPackages = true;
    }
    if (!leadPackageType && index === 0) {
      leadPackageType = (packages?.[0]?.type) || null;
    }

    // only show ads on today, news, and msnbc
    if (isEligibleForAdInsertion && !adsDisabled) {
      packages = adController.addCustomAds(layout, packages);
      if (customFrontAds) packages = customFrontAds.addCustomAds(layout, packages);
    }

    if (isCover && isTaboolaCustomPlacement) {
      packages = taboolaController.addCustomTaboola(packages);
    }

    // force rightrailtabbed to rightrail only to news homepage
    const forceOverrideRightRailTab = isNewsVertical && isCover;
    const isRailLayout = /rail/i.test(type);
    const isFirstRail = isRailLayout && !foundFirstRail;
    if (isFirstRail) foundFirstRail = true;

    const layoutComponent = getLayoutFor(type, { key: id, packages }, forceOverrideRightRailTab);
    return layoutComponent && (
      <ErrorBoundary key={`ErrorBoundary(${id})`}>
        <LayoutContext
          adsDisabled={adsDisabled}
          nonPackageAdsDisabled={nonPackageAdsDisabled}
          curationAutofill={curationAutofill}
          isFirstLayoutWithPackages={isFirstLayoutWithPackages}
          isFirstRail={isFirstRail}
          isRailLayout={isRailLayout}
          layoutIndex={index}
          layoutType={type}
          leadPackageType={leadPackageType}
          pageRoute={pageRoute}
          vertical={vertical}
          isFluidWidthPage={isFluidWidthPage}
          pageType={pageType}
          routePath={routePath}
        >
          {layoutComponent}
        </LayoutContext>
      </ErrorBoundary>
    );
  };
};

export default getLayout;
